<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Performance Management Review</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card elevation="0">
          <v-form v-model="addcat2" ref="addcat2">
            <v-layout wrap>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-select
                  v-model="employee"
                  :items="employeeList"
                  outlined
                  item-text="name"
                  item-value="_id"
                  dense
                  hide-details
                  label="Employee"
                  clearable
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="designation"
                  label="Designation"
                  required
                  hide-details
                  outlined
                  type="text"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="name"
                  label="Name"
                 
                  required
                  hide-details
                  outlined
                  type="text"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex> -->
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="grade"
                  label="Grade"
                 
                  required
                  hide-details
                  outlined
                  type="text"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="programmeOrDivision"
                  label="Programme / Division"
                 
                  required
                  hide-details
                  outlined
                  type="text"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-select
                  v-model="fyear"
                  :items="yearList"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                 
                  required
                  hide-details
                  label="Choose Year"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 align-self-center text-left pa-2>
                <v-text-field
                  v-model="rating"
                  label="Rating"
                  required
                  hide-details
                  outlined
                  type="text"
                  @wheel="$event.target.blur()"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 align-self-center text-center pa-2>
                <v-layout wrap justify-center py-4>
                  <v-flex xs12 sm6 md4 lg3 align-self-center px-4>
                    <v-btn
                      color="#005f32"
                      dark
                      tile
                      large
                      class="rounded-xl"
                      block
                      @click="validateInput()"
                    >
                      Submit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
        <!-- </v-dialog> -->

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      addcat2: false,
      docsdata: new FormData(),
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
       rules: {
        required: (value) => !!value || "Required.",
      },
      yearList:[],
      employeeList: [],
      menu: false,
      appLoading: false,
      employee: null,
      designation: null,
      // name: null,
      grade: null,
      programmeOrDivision: null,
      fyear: null,
      rating: null,
      employeeDetail:[],
    };
  },
  watch:{
    employee(){
         this.getemployee();
    }
  },
  mounted() {
    this.getEmployeeList();
 
    this.getyear();
  },
  methods: {
    validateInput() {
      if (!this.employee) {
        this.msg = "Please add employee id";
        this.showsnackbar = true;
        return;
      } else if (!this.rating) {
        this.msg = "Please provide rating";
        this.showsnackbar = true;
        return;
      } else {
        this.addpmr();
      }
    },
    addpmr() {
      this.appLoading = true;
      axios({
        url: "pmrscore/add",
        method: "POST",
        data: {
          employeeId: this.employee,
          designation: this.designation,
          name: this.name,
          grade: this.grade,
          programmeOrDivision: this.programmeOrDivision,
          fyear: this.fyear,
          rating: this.rating,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.employee = null;
            this.designation = null;
            this.name = null;
            this.grade = null;
            this.programmeOrDivision = null;
            this.fyear = null;
            this.rating = null;
            this.$router.push("/viewPMR");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
 getemployee() {
      this.appLoading = true;
      axios({
        url: "/employee/details/view",
        method: "GET",
        params: {
          id: this.employee,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeDetail = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getEmployeeList() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getyear() {
      axios({
        method: "get",
        url: "/financialyear/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.yearList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    //  browseCover(event) {
    //   var got = event.target.files;
    //   if (got.length < 5) {
    //     for (let i = 0; i < got.length; i++) {
    //       this.documentFile1.push(got[i]);
    //     }
    //     console.log("file isss", this.documentFile1);
    //   } else {
    //     this.msg = "Maximum 3 can be uploaded";
    //     this.showSnackBar = true;
    //   }
    // },
    // uploadFile() {
    //   var data = {};
    //   data["name"] = this.file1;
    //   axios({
    //     url: "/employee/award/add",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //        this.uploadImg()
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    //  addDocument() {
    //   if (this.documentFile1.length > 0) {
    //     this.appLoading = true;
    //     axios({
    //       url: "/policy/add",
    //       method: "post",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       data: {
    //         name: this.title,
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.documentID = response.data.data._id;
    //         console.log("documentID",this.documentID);
    //         console.log("documentfile",this.documentFile1)
    //         if (this.documentID) {
    //           this.docsupload();
    //         } else {
    //           alert("document updated");
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   } else {
    //     this.showsnackbar = true;
    //     this.msg = "Please Select Document ";
    //   }
    // },
    // docsupload() {
    //   for (var i = 0; i < this.documentFile1.length; i++) {
    //     this.docsdata.append("document", this.documentFile1[i]);
    //   }
    //   this.docsdata.append("id", this.documentID);

    //   this.appLoading = true;
    //   axios({
    //     url: "/upload/policydocument",
    //     method: "post",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     data: this.docsdata,
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.dialog = false;
    //       this.documentFile1 = [];
    //       this.docsdata = new FormData();
    //       (this.title = null),
    //         (this.showsnackbar = true);
    //       this.getData();
    //       this.msg = response.data.msg;
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
